<!-- 顶部 -->
<template>
<div class="root-241102">
    <div class="left"></div>
    <div class="title">{{ $getLang('匿名交流平台') }}</div>
    <div class="right">
        <div class="lang-container">
            <!-- <div class="label">语言</div> -->
            <el-select v-model="language" placeholder="请选择语言" class="select">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
        </div>
        <div class="user-container" title="点击查看用户信息" @click="showUserInfo">
            <img :src="avatarUrl" alt="" class="img"/>
            <div class="username">{{ userName }}</div>
        </div>
    </div>

    <el-dialog
        v-model="userInfoDialogVisible"
        title="用户信息"
        :close-on-click-modal="false"
    >
        <div id="myId202412291">
            <div class="row">
                <div class="label">用户标识</div>
                <div class="value">{{ dialogData.uuid }}</div>
            </div>
            <div class="row">
                <div class="label">用户名称</div>
                <template v-if="!isEditUsername">
                    <div class="value">{{ dialogData.username }}</div>
                    <div class="edit">
                        <el-button
                            text
                            type="primary"
                            @click="onClickEditUsername"
                        >
                            编辑
                        </el-button>
                    </div>
                </template>
                <template v-else>
                    <el-input v-model="dialogData.usernameModel" />
                    <div class="edit">
                        <el-button
                            text
                            type="primary"
                            @click="onClickSaveUsername"
                        >
                            保存
                        </el-button>
                    </div>
                </template>
            </div>
            <div class="row">
                <div class="label">用户头像</div>
                <div class="value">{{ dialogData.avatar }}</div>
            </div>
        </div>
    </el-dialog>
    <div v-if="false">
        <el-message></el-message>
    </div>
</div>
</template>

<script>
import {
    DEFAULT_USER_NAME
} from "@/static/staticValue"
import{
    ElSelect,
    ElOption,
    ElDialog,
    ElButton,
    ElInput,
    ElMessage
} from 'element-plus'
import {
    saveUserInfo
} from "@/api/user"
import {
    getUserInfo
} from "@/api/registerLogin"
import {
    langOption
} from "./data"
export default {
    name: "TopContainer",
    components: {
        ElSelect,
        ElOption,
        ElDialog,
        ElButton,
        ElInput,
        ElMessage
    },
    created() {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.avatarUrl = userInfo.avatar;
        if(userInfo.username != DEFAULT_USER_NAME) {
            this.userName = userInfo.username;
        }
        this.initUserDialog();
    },
    data() {
        return{
            language: 'zh-CN',
            options: langOption,    // 语言选项
            avatarUrl: "https://img1.baidu.com/it/u=3721293528,1391032071&fm=253&fmt=auto?w=171&h=171",      // 用户头像图片地址
            userName: "匿名用户",
            userInfoDialogVisible: false,    // 是否显示用户信息弹窗
            dialogData: {       // 用户信息弹窗数据
                uuid: '',
                username: '',
                avatar: '',
                usernameModel: ''
            },
            isEditUsername: false,      // 是否正在编辑用户名称
        }
    },
    methods: {
        // 初始化或者重新设置用户信息弹窗
        initUserDialog() {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            for(let key in userInfo) {
            if(key in this.dialogData) {
                this.dialogData[key] = userInfo[key];
            }
            this.dialogData.usernameModel = this.dialogData.username;
            this.isEditUsername = false;
        }
        },
        showUserInfo() {
            // console.log("查看用户信息");
            this.userInfoDialogVisible = true;
            
            this.initUserDialog();
            // console.log(this.dialogData);
        },
        onClickEditUsername() {
            // console.log("点击编辑");
            this.isEditUsername = true;
        },
        onClickSaveUsername() {
            // console.log("点击保存", this.dialogData.usernameModel);
            saveUserInfo({
                username: this.dialogData.usernameModel
            }).then(res => {
                // console.log("res", res);
                if(res.code === 1000) {
                    getUserInfo().then(res => {
                        localStorage.setItem("userInfo", JSON.stringify(res.result));
                        const userInfo = res.result;
                        if(userInfo.username != DEFAULT_USER_NAME) {
                            this.userName = userInfo.username;
                        }

                        this.initUserDialog();
                    })
                    ElMessage({
                        type: 'success',
                        message: '用户名称修改成功'
                    })
                    this.isEditUsername = false;
                }
            })
        }
    },
    watch: {
        language(newValue) {
            this.$store.commit("setLanguage", newValue);
            document.title = this.$getLang("匿名交流平台");
        }
    }
}
</script>

<style scoped>
.root-241102 {
    background: #efebeb52;
    border-bottom: none;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .left {
        flex-grow: 1;
    }

    .right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 13px;

        .lang-container {
            display: flex;
            align-items: center;
            .select {
                min-width: 100px;
            }
        }
        .user-container {
            border-radius: 8px;
            right: 145px;
            display: flex;
            align-items: center;
            margin-right: 7vw;
            .img {
                width: 30px;
                height: 30px;
                display: bolck;
                vertical-align: bottom;
            }
            .username {
                margin-left: 10px;
            }
        }
        .user-container:hover {
            cursor: pointer;
        }
    }
}
</style>
<style>
#myId202412291 {
    display: flex;
    flex-direction: column;
    gap: 25px;
    .row {
        display: flex;
        align-items: center;
        .label {
            min-width: 77px;
        }
        .value {
            flex-grow: 1;
        }
    }
}
</style>