<template>
  <template v-if="!isMyPage">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <button @click="enterMyPage">进入我的界面</button>
    <router-view/>
  </template>

  <div v-else class="root-class2411021" :refreshApp="refreshAppValue">
    <TopContainer></TopContainer>
    <div style="background:white; height: 15px;">&nbsp;</div>
    <div class="bottom-contianer" :style="showBeianhao? {paddingBottom:'0'} : {}">
      <LeftContainer></LeftContainer>
      <MainContainer></MainContainer>
    </div>
    <div class="beian-container" v-if="showBeianhao">
      备案号：
      <a href="https://beian.miit.gov.cn/" target="_blank">桂ICP备2024048802号-1</a>
    </div>
  </div>
  
</template>

<script>
import TopContainer from "@/layout/TopContainer.vue";
import LeftContainer from "@/layout/LeftSide.vue";
import MainContainer from "@/layout/MainContainer.vue"
import { mapState } from "vuex";
export default{
  components: {
    TopContainer,
    LeftContainer,
    MainContainer
  },
  data() {
    return {
      isMyPage: true,
      showBeianhao: true    // 是否展示底部备案号
    }
  },
  mounted() {
    document.title = this.$getLang("匿名交流平台");
  },
  computed: mapState([
    'refreshAppValue'
  ]),
  methods: {
    enterMyPage() {
      this.isMyPage = true;
    }
  }
}
</script>

<style scoped>
.root-class2411021 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 900px;
  background: #efebeb52;
  overflow-x: auto;
  .bottom-contianer {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    padding: 15px;
    padding-top: 0;
    /* padding-bottom: 0; */
  }
  .beian-container {
    /* border: 1px solid black; */
    padding: 15px;
    padding-top: 7px;
    color: #999;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    a {
      text-decoration: none;
      color: inherit;
    }
    a:hover {
      color: rgb(75, 158, 95);
    }
  }
}
</style>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
