import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/config/env'
import environment from "@/config/env";
import '@/styles/index.css'
import {
    getLang
} from "@/utils/language"
import{
    getAccessToken
} from "@/api/registerLogin"
import{
    calculateSHA256
} from "@/utils/crypto"
// import {
//     // testAES,
//     // myEncrypt,
//     test
// } from "@/utils/crypto"
if(window.location.origin.indexOf("www.icandomore.fun") != -1) {
    console.log("在此处选择是否禁用域名访问");
    // window.location = "http://120.76.239.116";
}

// 打印全局变量，便于开发
console.log("environment", environment);
console.log("process.env", process.env);
// 使用示例
calculateSHA256("hello world").then(hash => {
    console.log("SHA-256 Hash用法demo在此:", hash);
});

// test();
// console.log('a1', myEncrypt('a1'));
// testAES();

getAccessToken();

const app = createApp(App);
app.use(store);
app.use(router);
app.config.globalProperties.$getLang = getLang;
app.mount("#app");
