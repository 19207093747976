import { DEFAULT_USER_NAME } from "@/static/staticValue";
import { ElSelect, ElOption, ElDialog, ElButton, ElInput, ElMessage } from 'element-plus';
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/message/style/css'

import { saveUserInfo } from "@/api/user";
import { getUserInfo } from "@/api/registerLogin";
import { langOption } from "./data";
export default {
  name: "TopContainer",
  components: {
    ElSelect,
    ElOption,
    ElDialog,
    ElButton,
    ElInput,
    ElMessage
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.avatarUrl = userInfo.avatar;
    if (userInfo.username != DEFAULT_USER_NAME) {
      this.userName = userInfo.username;
    }
    this.initUserDialog();
  },
  data() {
    return {
      language: 'zh-CN',
      options: langOption,
      // 语言选项
      avatarUrl: "https://img1.baidu.com/it/u=3721293528,1391032071&fm=253&fmt=auto?w=171&h=171",
      // 用户头像图片地址
      userName: "匿名用户",
      userInfoDialogVisible: false,
      // 是否显示用户信息弹窗
      dialogData: {
        // 用户信息弹窗数据
        uuid: '',
        username: '',
        avatar: '',
        usernameModel: ''
      },
      isEditUsername: false // 是否正在编辑用户名称
    };
  },
  methods: {
    // 初始化或者重新设置用户信息弹窗
    initUserDialog() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      for (let key in userInfo) {
        if (key in this.dialogData) {
          this.dialogData[key] = userInfo[key];
        }
        this.dialogData.usernameModel = this.dialogData.username;
        this.isEditUsername = false;
      }
    },
    showUserInfo() {
      // console.log("查看用户信息");
      this.userInfoDialogVisible = true;
      this.initUserDialog();
      // console.log(this.dialogData);
    },
    onClickEditUsername() {
      // console.log("点击编辑");
      this.isEditUsername = true;
    },
    onClickSaveUsername() {
      // console.log("点击保存", this.dialogData.usernameModel);
      saveUserInfo({
        username: this.dialogData.usernameModel
      }).then(res => {
        // console.log("res", res);
        if (res.code === 1000) {
          getUserInfo().then(res => {
            localStorage.setItem("userInfo", JSON.stringify(res.result));
            const userInfo = res.result;
            if (userInfo.username != DEFAULT_USER_NAME) {
              this.userName = userInfo.username;
            }
            this.initUserDialog();
          });
          ElMessage({
            type: 'success',
            message: '用户名称修改成功'
          });
          this.isEditUsername = false;
        }
      });
    }
  },
  watch: {
    language(newValue) {
      this.$store.commit("setLanguage", newValue);
      document.title = this.$getLang("匿名交流平台");
    }
  }
};